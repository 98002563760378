<template>
	<div>
		<div id="bg_color" class="content-box">
		</div>
		<div id="bg_bottom_img">	
		</div>
		<div class="content-box-1">
			<div class="content-box-title">
				<span v-if="region_title">{{region_title}}</span>
				<span v-else>首届川陕革命老区{{region_name}}中小学红色研学实践课程设计大赛</span>
				<div class="login-info-select-btn" v-if="isLogin && this.$route.name != 'login'">
					<el-dropdown class="dropdown" @command="handleCommand">
						<span class="el-dropdown-link">
							<span>账户：{{userName}}</span>
							<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown" class="el-dropdown-menu">
							<el-dropdown-item command="edit" class="edit">退出登录</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>
			<div class='content-view' v-if="!flag">
				<keep-alive>
					　　<router-view v-if="$route.meta.keepAlive"></router-view>
				</keep-alive>
				<router-view v-if="!$route.meta.keepAlive"></router-view>
			</div>
			<!-- <div class="content-box-footer">
				<div>
					主办单位：新华文轩
				</div>
				<div>
					技术支持：存时科技
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				flag: false,
				userName: '',
				// 区域名称
				region_name: JSON.parse(sessionStorage.getItem('region_name')),
				region_title: sessionStorage.getItem('region_detail') ? JSON.parse(sessionStorage.getItem('region_detail')).region_title : '',
			}
		},
		computed: {
			isLogin() {
				let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
				if(userInfo) {
					if(userInfo.role == 'competition_course_expert') {
						this.userName = userInfo.username.length > 26 ? userInfo.username.substr(0, 26) + ' (专家)' : userInfo.username + ' (专家)'
					} else if(userInfo.role == 'competition_course_filter_expert') {
						this.userName = userInfo.username.length > 24 ? userInfo.username.substr(0, 24) + ' (筛查专家)' : userInfo.username + ' (筛查专家)'
					} else {
						this.userName = userInfo.username.length > 30 ? userInfo.username.substr(0, 30) : userInfo.username
					}
				}
				return this.$store.getters.getState
			},
		},
		methods: {
			handleCommand(command) {
				if (command == 'edit') {
					localStorage.clear()
					sessionStorage.clear()
					this.$store.dispatch("loginState", false);
					// 退出
					this.$router.push({
						name: 'login'
					})
					history.pushState(null, null, document.URL);
					window.addEventListener("popstate", function(e) {
						history.pushState(null, null, document.URL);
					}, false);
					this.getInitDetail()
				}
			},
			getInitDetail() {
				this.http.getArea().then(res => {
					res.data.data.list.forEach(item => {
						if (item.region_code == 510100) {
							this.http.getCourseFrontKey('?region_code=' + item.region_code).then(ress => {
								sessionStorage.setItem('region_key', JSON.stringify(ress.data.data.list))
							})
							this.http.getCourseRegionDetail('?id=' + item.id).then(ress => {
								sessionStorage.setItem('region_detail', JSON.stringify(ress.data.data.competition_course_region))
								sessionStorage.setItem('region_theme_text', JSON.stringify(ress.data.data.competition_course_region.region_theme_text))
								this.initPage()
							})
							sessionStorage.setItem('region_code', JSON.stringify(item.region_code))
							sessionStorage.setItem('region_name', JSON.stringify(item.relate_region_by_join ? item.relate_region_by_join.name : '成都市'))
							sessionStorage.setItem('region_id', JSON.stringify(item.id))
						}
					})
					
				})
			},
			initPage() {
				if(sessionStorage.getItem('region_detail') && JSON.parse(sessionStorage.getItem('region_detail')).region_bg_color) {
					document.getElementById("bg_color").style.backgroundColor = JSON.parse(sessionStorage.getItem('region_detail')).region_bg_color
				}
				if(sessionStorage.getItem('region_detail') && JSON.parse(sessionStorage.getItem('region_detail')).region_sub_bg) {
					document.getElementById("bg_bottom_img").style.backgroundImage = "url('" + this.alOssUrl + String(JSON.parse(sessionStorage.getItem('region_detail')).region_sub_bg) + "')"
				} else {
					document.getElementById("bg_bottom_img").style.backgroundImage = 'none'
				}
				this.region_title = sessionStorage.getItem('region_detail') ? JSON.parse(sessionStorage.getItem('region_detail')).region_title : ''
			}
		},
		beforeCreate() {
			this.http.getArea().then(res => {
				res.data.data.list.forEach(item => {
					if (item.region_code == 510100) {
						this.http.getCourseFrontKey('?region_code=' + item.region_code).then(ress => {
							sessionStorage.setItem('region_key', JSON.stringify(ress.data.data.list))
						})
						this.http.getCourseRegionDetail('?id=' + item.id).then(ress => {
							sessionStorage.setItem('region_detail', JSON.stringify(ress.data.data.competition_course_region))
							sessionStorage.setItem('region_theme_text', JSON.stringify(ress.data.data.competition_course_region.region_theme_text))
							this.initPage()
						})
						sessionStorage.setItem('region_code', JSON.stringify(item.region_code))
						sessionStorage.setItem('region_name', JSON.stringify(item.relate_region_by_join ? item.relate_region_by_join.name : '成都市'))
						sessionStorage.setItem('region_id', JSON.stringify(item.id))
					}
				})
				
			})
		}
	}
</script>

<style>
	* {
		margin: 0;
		padding: 0;
	}
	
	tr td {
		border: node;
	}
	
	a {
		text-decoration: none;
		color: black;
	}
	
	li {
		list-style: none;
	}
	
	/* 谷歌滚动条 */
	::-webkit-scrollbar-thumb:horizontal {
		/*水平滚动条的样式*/
		width: 10px;
		background-color: #CCCCCC;
		-webkit-border-radius: 6px;
	}
	
	::-webkit-scrollbar-track-piece {
		background-color: #fff;
		/*滚动条的背景颜色*/
		-webkit-border-radius: 3px;
		/*滚动条的圆角宽度*/
	}
	
	::-webkit-scrollbar {
		width: 13px;
		/*滚动条的宽度*/
		height: 8px;
		/*滚动条的高度*/
		-webkit-transition: all 2s linear;
		transition: all 2s linear;
	}
	
	::-webkit-scrollbar-thumb:vertical {
		/*垂直滚动条的样式*/
		height: 50px;
		background-color: #cacaca;
		-webkit-border-radius: 14px;
		outline: 2px solid #fff;
		outline-offset: -2px;
		border: 2px solid #fff;
	}
	
	::-webkit-scrollbar-thumb:hover {
		/*滚动条的hover样式*/
		height: 50px;
		background-color: #9f9f9f;
		-webkit-border-radius: 14px;
	}
	
	#bg_bottom_img {
		/* background-image: url(../assets/img/content-bg-bottom.png); */
		background-repeat: no-repeat;
		background-size: 100% 100%;
		width: 100%;
		min-width: 1220px;
		height: 300px;
		/* height: 31vh; */
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: -998;
	}
	.content-box {
		/* background-image: url(../assets/img/content-bg.png);
		background-repeat: no-repeat;
		background-size: 100% 100%; */
		background-color: #1950A2;
		width: 100%;
		min-width: 1220px;
		height: 100%;
		min-height: 800px;
		position: fixed;
		top: 0;
		left: 0;
		z-index: -999;
	}
	
	.content-box-title {
		/* width: 68%; */
		width: 75vw;
		min-width: 1000px;
		/* height: 70vh; */
		margin: 0 auto;
		padding: 1vw 8vw;
		
		
		/* width: 100%; */
		/* height: 47px; */
		/* font-size: 2.5vw; */
		/* text-align: center; */
		margin-top: 1vw;
		/* margin-bottom: 20px; */
		
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.content-box-title>span {
		/* font-size: 50px; */
		font-size: 1.9vw;
		font-family: Alibaba PuHuiTi;
		font-weight: bold;
		color: #ffffff;
		text-shadow: 3px 4px 0px rgba(0, 99, 145, 0.22);
		/* margin: 3.5vw 0 0 5.5vw; */
		letter-spacing: 5px;
	}
	/* 登录信息 */
	.el-dropdown-link {
		color: white !important;
		font-size: 16px;
	}
	
	.el-dropdown-link span {
		margin-right: 5px;
	}
	
	/*下拉*/
	.el-dropdown-link {
		cursor: pointer;
		color: #409EFF;
	}
	
	.el-icon-arrow-down {
		font-size: 16px;
		color: white;
	}
	
	.el-dropdown-menu {
		width: 150px;
		text-align: center;
		margin-top: -10px;
	}
	
	.el-dropdown-menu__item {
		font-size: 14px !important;
		height: 40px !important;
		line-height: 40px !important;
	}
	
	.el-dropdown-menu__item:focus,
	.el-dropdown-menu__item:not(.is-disabled):hover {
		background: #F5F5F5 !important;
		color: black;
	}
	
	.edit {
		color: red !important;
	}
	
	.login-info-select-btn {
		float: right;
		font-size: 16px;
		font-weight: 400;
		color: #FFFFFF;
	}
	.content-view {
		margin-top: 1vw;
		min-height: 76vh;
	}
	
	.content-box-footer {
		width: 10vw;
		min-width: 440px;
		margin: 0 auto;
		overflow: hidden;
	}
	
	.content-box-footer div {
		float: left;
		height: 35px;
		font-size: 20px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #999999;
		margin-right: 40px;
	}
	
	/* 浮动 */
	.float-left {
		float: left;
	}
	
	.float-right {
		float: right;
	}
	
	.overflow {
		overflow: hidden;
	}
	
	/* 统一input边框 */
	.input-border {
		width: 100%;
		height: 45px;
		min-height: 45px;
		background: #FFFFFF;
		box-shadow: 0px 3px 5px 0px rgba(255,59,61,0.1500), 0px -5px 5px 0px rgba(255,59,61,0.0500);
		border-radius: 25px;
		line-height: 45px;
		margin-bottom: 3vh;
	}
	
	.input-img {
		margin: 8px 15px;
		/* width: 4.5vh;
		height: 4.5vh;
		min-width: 35px;
		min-height: 35px; */
	}
	
	/* 按钮样式 */
	/* 普通按钮 */
	.btn-primary {
		/* width: 100px !important; */
		height: 40px !important;
		background: #FF3B3D !important;
		border: none;
		border-radius: 5px !important;
		font-size: 16px !important;
	}
	.btn-primary:hover {
		background: #C6191F !important;
	}
	/*自定义按钮custom-btn*/
	.custom-btn {
		width: 130px;
		height: 40px;
		background: #FFFFFF;
		/* box-shadow: 0px 3px 5px 0px rgba(255,59,61,0.1500), 0px -5px 5px 0px rgba(255,59,61,0.0500); */
		border-radius: 5px;
		text-align: center;
		line-height: 40px;
		cursor: pointer;
		font-weight: bold;
		color: #1FB4F9;
		margin-left: 20px;
	}
	
	/* 按钮颜色 */
	.btn-corlor-first {
		background-color: #FB824E;
		color: white !important;
	}
	.btn-corlor-first:hover {
		background: #EE5514;
	}
	
	.btn-corlor-second {
		background-color: #1FB4F9 !important;
		color: white !important;
	}
	
	/* 表格样式 */
	.el-table .el-table__header-wrapper .el-table__header th{
		height: 60px;
		background: rgba(255, 238, 238, 0.39) !important;
		border: 1px solid #FFF4F5;
	}
	.el-table .el-table__fixed-right .el-table__header th {
		height: 60px;
		background: rgba(255, 238, 238, 0.39) !important;
		border: 1px solid #FFF4F5;
	}
	
	/* 分页样式 */
	.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color: #FF3B3D;
	}
	.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
		color: #FFFFFF;
	}
	.el-pagination.is-background .el-pager li:not(.disabled):hover {
	    color: #FF3B3D;
	}
	
	.el-dialog {
		display: flex;
		flex-direction: column;
		margin: 0 !important;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		max-height: calc(100% - 30px);
		max-width: calc(100% - 30px);
		min-width: 300px;
	}
	
	
	.el-radio__input.is-checked .el-radio__inner {
		border-color: #1FB4F9;
		background: #1FB4F9;
	}
	
	.el-radio__input.is-checked+.el-radio__label {
		color: #1FB4F9;
	}
	
	.el-radio__input.is-checked .el-radio__inner::after {
		content: '';
		width: 10px;
		height: 5px;
		border: 1px solid white;
		border-top: transparent;
		border-right: transparent;
		text-align: center;
		display: block;
		position: absolute;
		top: 1px;
		left: 1px;
		vertical-align: middle;
		transform: rotate(-45deg);
		border-radius: 0px;
		background: none;
	}
	
	
	
	
	
	/*图片上传-CSS*/
	.el-upload--picture-card {
		border: 1px dashed #d9d9d9 !important;
		background: #F5F5F5 !important;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		width: 100px !important;
		height: 100px !important;
		line-height: 30px !important;
	}
	
	.add-img {
		padding-top: 30px !important;
		background: rgba(255, 238, 238, 0.39);
		box-sizing: border-box;
	}
	
	.add-img>div {
		font-size: 16px;
		font-weight: 400;
		color: #FF3B3D;
	}
	.el-upload--picture-card {
		width: 120px !important;
		height: 150px !important;
		background: rgba(255, 238, 238, 0.39) !important;
		border: none !important;
	}
	.el-upload--picture-card:hover {
		border: none !important;
	}
	
	.el-upload--picture-card:hover .add-img {
		color: #333333 !important;
	}
	
	.add-img img {
		width: 50px;
		height: 50px;
	}
	
	.el-upload--picture-card:hover {
		color: black !important;
	}
	.el-radio__input.is-checked .el-radio__inner {
		border-color: #db3737;
		background: #db3737;
	}
	.el-radio__input.is-checked+.el-radio__label {
		color: #db3737;
	}
	.el-checkbox__input.is-checked .el-checkbox__inner {
		border-color: #db3737 !important;
		background: #db3737 !important;
	}
	.el-checkbox__input.is-checked+.el-checkbox__label {
	    color: #db3737 !important;
	}
	.w-600 {
		width: 600px;
	}
	/*图片list*/
	.el-upload-list__item,
	.is-ready img {
		width: 120px !important;
		height: 150px !important;
	}
	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
	
	/* dialog样式 */
	.el-dialog__header {
	    height: 60px !important;
	    padding: 0;
	    line-height: 60px;
	    margin: 0 20px;
	    border-bottom: 1px solid #E5E5E5;
	}
	
	/*隐藏*/
	/*拖拽上传-CSS*/
	.el-upload-dragger {
		border-radius: 0 !important;
		width: 610px !important;
		height: 120px !important;
		/* line-height: 120px; */
		font-size: 16px;
		padding-top: 20px;
		border: 1px solid #E5E5E5;
	}
	.el-upload-dragger:hover {
		border-color: #db3737;
	}
	
	.el-upload-dragger .el-upload__text em {
		color: #FF3B3D !important;
	}
	
	.el-upload-dragger .el-upload__text p {
		font-size: 10px;
		color: #999999;
	}
	
	/*拖拽上传list-CSS*/
	.el-upload-list,
	.el-upload-list--text {
		width: 300px;
	}
	
	.el-upload-list__item-name {
		width: 300px;
		border: 1px dashed #E5E5E5;
		/*margin-bottom: 25px;*/
	}
	
	.upload-demo /deep/ .el-upload-list {
		display: none;
	}
	
	.my-upload-list {
		font-size: 14px;
		color: #797979;
	}
	
	.my-upload-list>div {
		width: 610px;
		height: 43px;
		/* border: 1px dashed #d9d9d9; */
		border-bottom: 1px solid #E5E5E5;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 10px;
	}
	
	.my-upload-list span {
		margin-right: 10px;
		display: inline-block;
		padding: 6px 12px;
		line-height: 14px;
		border-radius: 5px;
	}
	.my-upload-list span:hover {
		background: rgba(230,230,230,0.3900);
	}
	
	.upload-flex {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	
	.my-upload-list img {
		margin: 0 10px;
	}
	
	.show-flie-open {
		/* margin-right: 34px !important; */
		cursor: pointer;
		color: #1FB3F7;
	}
	.my-close {
		cursor: pointer;
		color: red;
	}
	
	.upload-demo .el-upload-list {
		display: none !important;
	}
	
	.upload-demo .el-upload-list--text {
		display: none !important;
	}
	
	/* 单选样式 1FB4F9 */
	.el-checkbox.is-bordered.is-checked {
		border-color: #1FB4F9
	}
	
	.el-checkbox__input.is-checked+.el-checkbox__label {
		color: #1FB4F9
	}
	
	.el-checkbox__input.is-checked .el-checkbox__inner,
	.el-checkbox__input.is-indeterminate .el-checkbox__inner {
		background-color: #1FB4F9;
		border-color: #1FB4F9
	}
	.confirmClass {
		background: #FF3B3D;
		border: none;
		color: #FFFFFF;
	}
	.confirmClass:hover {
		background-color: #FF3B3D;
	}
	.confirmCancelClass {
		background-color: #FF3B3D;
		color: #333333;
	}
	.confirmCancelClass:hover {
		background-color: #D1D1D1;
		color: #333333;
	}
	/* el-message-box */
	
	.el-message-box {
		padding: 0 20px 30px 20px;
	}
	
	.el-message-box__header {
		height: 60px;
		padding: 0;
		border-bottom: 1px solid #E5E5E5;
	}
	
	.el-message-box__title {
		text-align: center;
		line-height: 60px;
		font-weight: bold;
	}
	
	.el-message-box__content {
		min-height: 40px;
	}
	
	.el-message-box__btns {
		text-align: center;
	}
	
	.el-message-box__btns button {
		height: 33px;
	}
	
	.el-message-box__btns button:nth-of-type(1) {
		border-radius: 5px;
		background: #E8E8E8 !important;
		font-size: 14px;
		color: #333333;
		margin-right: 20px;
	}
	.el-message-box__btns button:nth-of-type(1):hover {
		background: #D1D1D1 !important;
	}
	.el-message-box__btns button:nth-of-type(2) {
		border-radius: 5px;
		background: #FF3B3D !important;
		font-size: 14px;
		color: white;
	}
	.el-message-box__btns button:nth-of-type(2):hover {
		background: #C6191F !important;
	}
	.el-button--primary {
		border: none;
	}
	.el-popover {
		max-width: 15vw;
	}
	.el-checkbox.is-bordered.is-checked {
	    border-color: #FF3B3D;
	}
	
	.checkbox-div {
		display: inline;
		position: relative;
	}
	.checkbox-div .icon-change {
		position: absolute;
		cursor: pointer;
		right: 110px;
		top: 4px;
		color: #606266;
	}
</style>
